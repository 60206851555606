import 'core-js/stable';

// TEMPLATE CSS
import '../template/css/bootstrap.min.css';
import '../template/css/bootstrap-extend.min.css';
import '../template/css/site.min.css';
import '../template/skins/blue.css';
import '../template/examples/css/pages/login-v3.css';
import '../template/vendor/animsition/animsition.css';
import '../template/vendor/asscrollable/asScrollable.css';
import '../template/vendor/switchery/switchery.css';
import '../template/vendor/intro-js/introjs.css';
import '../template/vendor/slidepanel/slidePanel.css';
import '../template/vendor/waves/waves.css';
import '../template/vendor/chartist/chartist.css';
import '../template/vendor/jvectormap/jquery-jvectormap.css';
import '../template/vendor/chartist-plugin-tooltip/chartist-plugin-tooltip.css';
import '../template/examples/css/dashboard/v1.css';
import '../template/fonts/material-design/material-design.min.css';
import '../template/fonts/brand-icons/brand-icons.min.css';

import '../stylesheets/devise.scss'

require("@rails/ujs").start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

